import { Link } from 'gatsby';
import * as React from 'react';
import { Card } from 'react-bootstrap';

const BlogPost = ({ post }) => {
  return (
    <Card className="my-4" style={{ maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}>
      <Card.Body>
        <Card.Title className="font-primary" as="h3">{post.title}</Card.Title>
        <Card.Text>{post.description}</Card.Text>
        <Card.Text>
          <small className="text-muted">
            Published {(new Date(post.date)).toLocaleDateString()}
          </small>
        </Card.Text>
        <Link to={`/blog/${post.slug}`} className="btn btn-primary">Read</Link>
      </Card.Body>
    </Card>
  )
}

export default BlogPost
