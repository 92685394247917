import { graphql } from "gatsby";
import * as React from "react";
import Layout from '../components/layout';
import { Container } from "react-bootstrap";
import BlogPost from "../components/blog-post";

const BlogPage = ({ data }) => {
  return (
    <Layout title="Blog">
      <section>
        <Container>
          <h1 className="text-center">Blog</h1>
          {
            data.allGraphCmsBlogPost.nodes.map((node) => (
              <BlogPost key={node.id} post={node}/>
            ))
          }
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allGraphCmsBlogPost(sort: {fields: date, order: DESC}) {
      nodes {
        id
        title
        date
        slug
        description
      }
    }
  }
`

export default BlogPage
